import React, { useRef, useState } from "react";
import {
  Modal,
  Zoom,
  Box,
  Button,
  Typography,
  Select,
  MenuItem,
  Snackbar,
  Alert,
  Tooltip,
  useMediaQuery,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Menu,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { AddCircle, Cancel, Close, Delete } from "@mui/icons-material";
import ModalLoading from "../../components/Reuse/modal/ModalLoading";
import {
  generateExcelCsv,
  getSprinterPickup,
} from "../../store/actions/dropperAction";
import { utils, writeFile } from "xlsx";
import { handleError } from "../../helper/helpers";

const UploadWithFilters = () => {
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [filters, setFilters] = useState([]);
  const [sprinterPickupOptions, setSprinterPickupOptions] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [loadingValidate, setLoadingValidate] = useState(false);
  const [loadingValidateText, setLoadingValidateText] = useState("");
  const [error, setError] = useState(null);
  const tableWrapperRef = useRef(null);
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(null);
  const [menuVisible, setMenuVisible] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [anchorEl, setAnchorEl] = useState(null);
  const [res, setRes] = useState({ status: "success", message: "" });
  const [showResponse, setShowResponse] = useState(false);

  const handleFileUpload = async (event) => {
    const uploadedFile = event.target.files[0];
    if (!uploadedFile) return;

    setFile(uploadedFile);
    setLoading(true);
    setLoadingValidate(true);
    setLoadingValidateText("Memproses file...");
    const formData = new FormData();
    formData.append("file", uploadedFile);

    try {
      const response = await getSprinterPickup(formData);

      setSprinterPickupOptions(response.data);
      setFilters([{ sprinterPickup: [""] }]);
      setAlertMessage(
        "File berhasil diunggah dan daftar Sprinter Pickup diperoleh."
      );
      setAlertSeverity("success");
    } catch (error) {
      console.error(error);
      setError(
        error.response.data.message || "Terjadi kesalahan saat memuat file."
      );
      setAlertMessage("Terjadi kesalahan saat memuat file.");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
      setLoadingValidate(false);
      setLoadingValidateText("");
      setShowSnackbar(true);
    }
  };

  const handleSubmitFilters = async () => {
    const isValid = filters.every(
      (filter) =>
        filter.sprinterPickup.length > 0 &&
        filter.sprinterPickup.every((item) => item.trim() !== "")
    );
    if (!isValid) {
      setAlertMessage("Semua kategori harus memiliki pilihan Sprinter Pickup.");
      setAlertSeverity("error");
      setShowSnackbar(true);
      return;
    }

    if (!file) {
      setAlertMessage("Harap unggah file terlebih dahulu.");
      setAlertSeverity("error");
      setShowSnackbar(true);
      return;
    }

    setLoading(true);
    setLoadingValidate(true);
    setLoadingValidateText("Memproses file...");

    const formData = new FormData();
    formData.append("file", file);
    formData.append("filters", JSON.stringify(filters));

    try {
      const response = await generateExcelCsv(formData);

      const fileBlob = response;
      const link = document.createElement("a");
      link.href = URL.createObjectURL(fileBlob);
      link.download = "result.zip";
      link.click();

      setAlertMessage("File berhasil diproses dan diunduh.");
      setAlertSeverity("success");
    } catch (error) {
      console.error(error);
      if (error.response && error.response.data) {
        const serverError = error.response.data;
        setAlertMessage(serverError.message || "Gagal memproses file.");
      } else {
        setAlertMessage(
          error.message || "Terjadi kesalahan saat memproses file."
        );
      }
      setAlertSeverity("error");
    } finally {
      setLoading(false);
      setShowSnackbar(true);
      setLoadingValidate(false);
      setLoadingValidateText("");
      resetStates();
      setOpenModal(false);
    }
  };

  const addFilter = () => {
    setFilters([...filters, { sprinterPickup: [""] }]);
  };

  const removeFilter = (index) => {
    const newFilters = filters.filter((_, idx) => idx !== index);
    setFilters(newFilters);
  };

  const handleSprinterPickupChange = (filterIndex, pickupIndex, value) => {
    const updatedFilters = [...filters];
    updatedFilters[filterIndex].sprinterPickup[pickupIndex] = value;
    setFilters(updatedFilters);
  };

  const addSprinterPickup = (filterIndex) => {
    const updatedFilters = [...filters];
    updatedFilters[filterIndex].sprinterPickup.push("");
    setFilters(updatedFilters);

    const scrollPosition = tableWrapperRef.current.scrollTop;
    setTimeout(() => {
      tableWrapperRef.current.scrollTop = scrollPosition;
    }, 100);
  };

  const removeSprinterPickup = (filterIndex, pickupIndex) => {
    const updatedFilters = [...filters];
    updatedFilters[filterIndex].sprinterPickup.splice(pickupIndex, 1);
    setFilters(updatedFilters);
  };

  const resetStates = () => {
    setFile(null);
    setFilters([]);
    setSprinterPickupOptions([]);
    setLoading(false);
    setLoadingValidate(false);
    setLoadingValidateText("");
    setError(null);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const closeModalAndReset = () => {
    closeModal();
    resetStates();
  };

  const handleOpenMenu = (event, index) => {
    const rect = event.target.getBoundingClientRect();
    setMenuPosition({ x: rect.left, y: rect.bottom });
    setAnchorEl(event.currentTarget);
    setCurrentCategoryIndex(index);
    setMenuVisible(true);
  };

  const handleCloseMenu = () => {
    setMenuVisible(false);
    setAnchorEl(null);
  };

  const StyledBox = styled(Box)({
    width: matchesMobile ? "90%" : "70%",
    height: "auto",
    backgroundColor: "white",
    borderRadius: 8,
    padding: "16px",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowY: "auto",
    maxHeight: "80vh",
  });

  const TableWrapper = styled(TableContainer)({
    marginTop: "20px",
    marginBottom: "20px",
    width: "100%",
    boxShadow: "none",
  });

  const buttonStyles = {
    borderColor: "#333333",
    color: "#333333",
    "&:hover": {
      borderColor: "#333333",
      backgroundColor: "#333333",
      color: "#ffffff",
    },
    mt: 1,
  };

  const downloadTemplate = async () => {
    try {
      const rows = [
        {
          "No. Waybill": "",
          "Waktu Input": "",
          "Tanggal Pengiriman": "",
          "Diinput oleh": "",
          "Diinput oleh DP": "",
          "Sprinter Pickup": "",
          "Drop Point": "",
          "Klien Pengirim": "",
          Tujuan: "",
          "Cara Pembayaran": "",
          "Harga Standar": "",
          "Biaya Kirim": "",
          "Biaya Asuransi": "",
          "Biaya Lainnya": "",
          "Total Biaya": "",
          "Biaya COD": "",
          "Sumber Order": "",
          "Jenis Layanan": "",
          "Nama Pengirim": "",
          "Telepon Pengirim": "",
          "HP Pengirim": "",
          "Kota Pengirim": "",
          "Alamat Pengirim": "",
          "Nama Penerima": "",
          "Telepon Penerima": "",
          "HP Penerima": "",
          "Provinsi Penerima": "",
          "Kota Penerima": "",
          "Kecamatan Penerima": "",
          "Alamat Penerima": "",
          "Metode Pengiriman": "",
          "Verifikasi AWB": "",
          "Jenis Barang": "",
          "Nama Barang": "",
          "Isi Paket": "",
          "Berat Ditagih": "",
          Keterangan: "",
          TTD: "",
          "Droppoint TTD": "",
          "Waktu Upload TTD": "",
          "Apakah VOID": "",
          "Klien Kepemilikan": "",
        },
      ];

      const wscols = Array(40).fill({ width: 20 });

      const ws = utils.json_to_sheet(rows);
      ws["!cols"] = wscols;
      const wb = utils.book_new();

      utils.book_append_sheet(wb, ws, "Data");

      writeFile(wb, "Upload_Excel_Template.xlsx");

      setRes({ status: "success", message: "berhasil download template" });
      setShowResponse(true);
    } catch (error) {
      const message = await handleError(error);
      if (message.cancel) return true;
      setRes({ status: "error", message: "gagal download template" });
      setShowResponse(true);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Tooltip title="Upload data sesuai template">
        <Button
          variant="contained"
          sx={{
            borderRadius: 0,
            height: matchesMobile ? "100%" : "30%",
            width: "100%",
            mb: matchesMobile ? 1 : 2,
            backgroundColor: "#ab8c36",
            boxShadow: matchesMobile
              ? null
              : "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
            "&:hover": {
              backgroundColor: "#333333",
              boxShadow: "2px -4px 5px 0px rgba(0, 0, 0, 0.5) !important",
            },
          }}
          onClick={() => setOpenModal(true)}
        >
          Excel/CSV
        </Button>
      </Tooltip>

      <ModalLoading
        loadingUpload={loadingValidate}
        text={loadingValidateText}
      />
      <Modal
        open={openModal}
        onClose={closeModal}
        sx={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Zoom in={openModal}>
          <StyledBox>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                Unggah Excel/CSV Filter
              </Typography>
              <Button
                size="small"
                onClick={closeModalAndReset}
                sx={{
                  color: "#bababa",
                  "&:hover": {
                    color: "#333333",
                    backgroundColor: "transparent",
                  },
                }}
              >
                <Close />
              </Button>
            </Box>

            {!file ? (
              <Box
                sx={{
                  height: "100%",
                  width: "95%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  component="label"
                  sx={{
                    width: "100%",
                    height: "150px",
                    border: "2px dashed #bababa",
                    borderRadius: 4,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    "&:hover": {
                      borderColor: "#333333",
                      color: "#333333",
                      cursor: "pointer",
                    },
                  }}
                >
                  <input
                    type="file"
                    hidden
                    accept=".xlsx"
                    onChange={handleFileUpload}
                  />
                  <Typography>Klik atau Seret File di Sini</Typography>
                  <Typography variant="caption" sx={{ mt: 1 }}>
                    Format yang diterima: .xlsx
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    height: "20%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    mt: 2,
                  }}
                >
                  <Button
                    onClick={() => downloadTemplate()}
                    variant="outlined"
                    sx={{
                      borderColor: "#333333",
                      color: "#333333",
                      "&:hover": {
                        borderColor: "#333333",
                        backgroundColor: "#333333",
                        color: "#ffffff",
                      },
                    }}
                  >
                    Download Template
                  </Button>
                </Box>
              </Box>
            ) : error ? (
              <Alert
                variant="outlined"
                severity="error"
                sx={{ color: "#333333", border: 0 }}
              >
                {error}
              </Alert>
            ) : (
              <>
                {/* Modal Container */}
                <Box
                  sx={{
                    padding: 2,
                    backgroundColor: "white",
                    borderRadius: 2,
                    width: "100%",
                    maxHeight: "90vh",
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  {/* Tabel */}
                  <Box
                    ref={tableWrapperRef}
                    sx={{
                      flex: 1,
                      overflowY: "auto",
                      border: "1px solid #ddd",
                      borderRadius: 1,
                    }}
                  >
                    <Table sx={{ minWidth: "600px" }}>
                      <TableHead
                        sx={{
                          position: "sticky",
                          top: 0,
                          backgroundColor: "#f5f5f5",
                          zIndex: 1,
                        }}
                      >
                        <TableRow>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Kategori
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Sprinter Pickup
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Aksi
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filters.map((filter, categoryIndex) => (
                          <TableRow
                            key={categoryIndex}
                            sx={{
                              backgroundColor:
                                categoryIndex % 2 === 0 ? "#fafafa" : "white",
                            }}
                          >
                            <TableCell sx={{ width: "30%" }}>
                              <Typography variant="body1">
                                {filter.categoryName ||
                                  `Kategori ${categoryIndex + 1}`}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 1,
                                }}
                              >
                                {filter.sprinterPickup.map(
                                  (pickup, pickupIndex) => (
                                    <Box
                                      key={pickupIndex}
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 1,
                                      }}
                                    >
                                      <Select
                                        value={pickup}
                                        onChange={(e) =>
                                          handleSprinterPickupChange(
                                            categoryIndex,
                                            pickupIndex,
                                            e.target.value
                                          )
                                        }
                                        fullWidth
                                        displayEmpty
                                        sx={{
                                          "& .MuiSelect-select": {
                                            padding: "8px 12px",
                                          },
                                        }}
                                      >
                                        <MenuItem value="" disabled>
                                          Pilih Sprinter Pickup
                                        </MenuItem>
                                        {sprinterPickupOptions?.map(
                                          (pickupOption, index) => (
                                            <MenuItem
                                              key={index}
                                              value={pickupOption}
                                              disabled={filter.sprinterPickup.includes(
                                                pickupOption
                                              )}
                                            >
                                              {pickupOption}
                                            </MenuItem>
                                          )
                                        )}
                                      </Select>

                                      <Cancel
                                        sx={{ cursor: "pointer" }}
                                        onClick={() =>
                                          removeSprinterPickup(
                                            categoryIndex,
                                            pickupIndex
                                          )
                                        }
                                      />
                                    </Box>
                                  )
                                )}
                                {filter.sprinterPickup.length === 0 && (
                                  <Typography
                                    variant="caption"
                                    sx={{ color: "#aaa", textAlign: "left" }}
                                  >
                                    Belum ada Sprinter Pickup yang ditambahkan
                                  </Typography>
                                )}
                              </Box>
                            </TableCell>

                            <TableCell sx={{ width: "20%", textAlign: "left" }}>
                              <div>
                                <Button
                                  variant="outlined"
                                  size="small"
                                  onClick={(event) =>
                                    handleOpenMenu(event, categoryIndex)
                                  }
                                  sx={{
                                    borderColor: "#333333",
                                    color: "#333333",
                                    "&:hover": {
                                      borderColor: "#333333",
                                      backgroundColor: "#333333",
                                      color: "#ffffff",
                                    },
                                  }}
                                >
                                  Opsi
                                </Button>

                                <Menu
                                  anchorEl={anchorEl}
                                  open={menuVisible}
                                  onClose={handleCloseMenu}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                  }}
                                  style={{
                                    position: "absolute",
                                    top: `${menuPosition.y}px`,
                                    left: `${menuPosition.x}px`,
                                  }}
                                >
                                  <MenuItem
                                    onClick={() => {
                                      if (currentCategoryIndex !== null) {
                                        addSprinterPickup(currentCategoryIndex); // Menambah pickup
                                      }
                                      handleCloseMenu();
                                    }}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    disabled={
                                      filter.sprinterPickup.length >=
                                        sprinterPickupOptions.length ||
                                      sprinterPickupOptions.length <= 1
                                    }
                                  >
                                    <AddCircle sx={{ marginRight: "8px" }} />{" "}
                                    Tambah Pickup
                                  </MenuItem>

                                  <MenuItem
                                    onClick={() => {
                                      if (currentCategoryIndex !== null) {
                                        removeFilter(currentCategoryIndex);
                                      }
                                      handleCloseMenu();
                                    }}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Delete sx={{ marginRight: "8px" }} /> Hapus
                                    Kategori
                                  </MenuItem>
                                </Menu>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>

                  {/* Footer Buttons */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingTop: 2,
                    }}
                  >
                    <Button
                      variant="outlined"
                      onClick={addFilter}
                      size="small"
                      sx={{
                        borderColor: "#333333",
                        color: "#333333",
                        "&:hover": {
                          borderColor: "#333333",
                          backgroundColor: "#333333",
                          color: "#ffffff",
                        },
                      }}
                    >
                      Tambah Kategori
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmitFilters}
                      sx={{
                        backgroundColor: "#ab8c36",
                        "&:hover": {
                          backgroundColor: "#896d2b",
                        },
                      }}
                    >
                      Generate
                    </Button>
                  </Box>
                </Box>
              </>
            )}
          </StyledBox>
        </Zoom>
      </Modal>

      <Snackbar
        open={showSnackbar}
        autoHideDuration={4000}
        onClose={() => setShowSnackbar(false)}
      >
        <Alert
          onClose={() => setShowSnackbar(false)}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default UploadWithFilters;
