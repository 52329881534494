import {
  Grid,
  Container,
  Typography,
  Box,
  Button,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  tableCellClasses,
  Modal,
  Tooltip,
  Zoom,
  Fade,
  TablePagination,
  CircularProgress,
  Alert,
  Menu,
  MenuItem,
  TextField,
  useMediaQuery,
  FormControl,
  InputAdornment,
  Snackbar,
  Slide,
  Divider,
  Link,
  InputLabel,
  Select,
  OutlinedInput,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { handleError } from "../../helper/helpers";
import { styled, useTheme } from "@mui/material/styles";
import correct from "../../audio/correct.mpeg";
import wrong from "../../audio/wrong.mp3";
import { dateFormat, timeFormat } from "../../helper/DateFormat";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { ThisMonth, formatDate } from "../../helper/custom";
import {
  Close,
  DeleteOutlined,
  EditOutlined,
  CancelOutlined,
  DoneOutlined,
  Search,
  RestoreFromTrashOutlined,
  InfoOutlined,
  EmojiEvents,
  RefreshOutlined,
  Sync,
} from "@mui/icons-material";
import { useState, useMemo, useEffect, useRef } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { DateRangePicker } from "react-date-range";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { compress } from "compress-json";
import { read, utils, writeFile } from "xlsx";
import { TablePaginationActions } from "../../components/Reuse/pagination/pagination";
import { convertDataToQuery, getQueryUrl } from "../../helper/custom";
import {
  getOrders,
  getOrdersValidate,
  getDetailLottery,
  uploadOrder,
  deleteOrder,
  deleteOrderPermanent,
  updateOrder,
  getCheckedOrder,
  checkedOrder,
  getOrdersPrint,
  restoreOrder,
  updateStatusPackage,
  getManifest,
  updateToRetur,
} from "../../store/actions/transactionAction";
import jwt_decode from "jwt-decode";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { ComponentToPrint } from "../../components/Print/Print";
// import { previousDay } from "date-fns";
import { useReactToPrint } from "react-to-print";
import ModalLoading from "../../components/Reuse/modal/ModalLoading";
import { Waypoint } from "react-waypoint";
import moment from "moment";
// import UploadExcelFilter from "./uploadExcelFilter";
import UploadWithFilters from "../../components/Modal/UploadWithFilters";
function OrderPage() {
  let componentRef = useRef();
  let inputScanAwb = useRef();

  // format date
  const options = {
    month: "numeric",
    day: "numeric",
    year: "numeric",
  };

  //for decode token
  const token = localStorage.getItem("access_token");
  const decode = jwt_decode(token);
  const accessStatus = decode.accessStatus;

  const upper = useRef(null);
  const scrollToUpper = () => {
    if (!loading) {
      if (upper.current) upper.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  //modal
  const [text, setText] = useState("");
  const [openModalPrint, setOpenModalPrint] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [openMenuScan, setOpenMenuScan] = useState(false);
  const [openModalErrorChecked, setOpenModalErrorChecked] = useState(false);
  const [openModalUpload, setOpenModalUpload] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalRestore, setOpenModalRestore] = useState(false);
  const [openModalDetailValidation, setOpenModalDetailValidation] =
    useState(false);
  const [openModalLottery, setOpenModalLottery] = useState(false);
  const [openModalStatusPackage, setOpenModalStatusPackage] = useState(false);
  const [openModalCsv, setOpenModalCsv] = useState(false);
  //modal

  //=================

  //loading
  const [loading, setLoading] = useState(true);
  const [loadingValidate, setLoadingValidate] = useState(true);
  const [loadingChecked, setLoadingChecked] = useState(true);
  const [loadingScan, setLoadingScan] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(true);
  const [loadingSearchLottery, setLoadingSearchLottery] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [loadingDetailStatus, setLoadingDetailStatus] = useState(false);
  const [loadingUpdateStatus, setLoadingUpdateStatus] = useState(-1);
  //loading
  const isAppleProduct =
    navigator.userAgent.match(/Macintosh|iPad|iPhone|iPod/i) !== null;
  //=================

  //for show and hidden
  const [scanManual, setScanManual] = useState(false);
  const [isEdit, setIsEdit] = useState(-1);
  const [typeUpload, setTypeUpload] = useState("");
  const [showResponse, setShowResponse] = useState(false);
  //for show and hidden

  //=================

  //audio
  const [audioCorrect] = useState(new Audio(correct));
  const [audioWrong] = useState(new Audio(wrong));
  //audio

  //=================

  //for containin data
  const [date, setDate] = useState("");
  const [errorUploadChecked, setErrorUploadChecked] = useState([]);
  const [dataChecked, setDataChecked] = useState([]);
  const [dataCheckedTotal, setDataCheckedTotal] = useState([]);

  const [dataValidate, setDataValidate] = useState([]);
  const [count, setCount] = useState(0);
  const [dataList, setDataList] = useState([]);
  const [detailValidate, setDetailValidate] = useState([]);
  const [totalDetailValidate, setTotalDetailValidate] = useState([]);

  const [selectedExpedition, setSelectedExpedition] = useState([]);
  const [allPrintData, setAllPrintData] = useState([]);

  const [printData, setPrintData] = useState([]);
  const [previousService, setPreviousService] = useState("");
  const [previousExpedition, setPreviousExpedition] = useState("");
  const [checked, setChecked] = useState("");
  const [editDate, setEditDate] = useState("");
  const [selectedData, setSelectedData] = useState("");
  const [res, setRes] = useState({ status: "success", message: "" });
  const [searchCode, setSearchCode] = useState("");
  const [filterDetail, setFilterDetail] = useState("");
  const [dataLottery, setDataLottery] = useState({
    no_id: "",
    awb: "",
    kodeUnique: "",
    expedition: "",
    service: "",
    order_date: "",
    lotteryStatus: "",
  });
  const [dataManifest, setDataManifest] = useState([]);
  const [errorUpdateStatus, setErrorUpdateStatus] = useState([]);
  //for containin data

  //=================

  //for change state
  const [idEdit, setIdEdit] = useState(0);
  const [idDelete, setIdDelete] = useState(0);
  const [state, setState] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  //for change state
  const [editData, setEditData] = useState({});
  const [error, setError] = useState("");
  const [errorScan, setErrorScan] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);
  const [errorPrint, setErrorPrint] = useState("");
  const [selectedStatusPackage, setSelectedStatusPackage] = useState("");
  const [seller, setSeller] = useState(
    decode.role ? decode.users[0]?.ShopId : 0
  );
  //for change state

  //=================

  //for get query
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);
  const selectedStatus = params.status;
  //for get query

  const [searchValue, setSearchValue] = useState(params.search || "");
  //=================

  //for navigate
  const navigate = useNavigate();
  const { search, pathname } = useLocation();

  const push = (payload) => {
    navigate(`/order${convertDataToQuery(payload)}`);
  };

  const query = useMemo(() => {
    if (!search) return null;
    return getQueryUrl(search);
  }, [search]);
  //for navigate

  //=================

  //for styled
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#ffffff",
    },
  }));
  //for styled

  //=================

  //=================
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const selectPrintedExpedition = (e) => {
    const {
      target: { value },
    } = e;
    setSelectedExpedition(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  //=================

  //for decode token

  //=================

  //edit transaction
  const editSubmit = async (e) => {
    try {
      e.preventDefault();
      Object.keys(editData).forEach((e) => {
        editData[e] = editData[e].trim();
      });
      const data = await updateOrder({
        payload: editData,
        id: idEdit,
      });

      let newArr = [...dataList];

      newArr[isEdit] = {
        ...newArr[isEdit],
        id: idEdit,
        awb: data.awb,
        order_id: data.order_id,
        expedition: data.expedition,
        service: data.service,
      };
      setDataList(newArr);
      setIsEdit(-1);
      let temp = { ...dataValidate };
      let previous = false;
      let next = false;
      let temp2 = { ...data };
      temp2.checkedDate = "";
      temp2.order_date = editDate;
      temp2.id = idEdit;
      if (checked) {
        temp2.checkedDate = new Date();
      }
      Object.keys(dataValidate).forEach((e) => {
        if (e !== "total") {
          if (
            `${data.expedition.toLowerCase()} ${data.service.toLowerCase()}` ===
            e.toLowerCase()
          ) {
            temp[e].totalNotChecked += 1;
            next = true;
          } else {
            next = false;
          }
          if (
            `${previousExpedition.toLowerCase()} ${previousService.toLowerCase()}` ===
            e.toLowerCase()
          ) {
            if (
              `${previousExpedition.toLowerCase()} ${previousService.toLowerCase()}` !==
              `${data.expedition.toLowerCase()} ${data.service.toLowerCase()}`
            ) {
              if (temp[e].totalNotChecked === 1) {
                delete temp[e];
              } else {
                temp[e].totalNotChecked -= 1;
                if (checked) {
                  temp[e].totalChecked -= 1;
                }
                let index = temp[e].data.findIndex((e) => e.awb === data.awb);
                if (index > -1) {
                  temp[e].data.splice(index, 1);
                }
              }
              previous = true;
            }
          } else {
            previous = false;
          }
        }
      });
      setPreviousService("");
      setPreviousExpedition("");
      if (!next || !previous) {
        if (!temp[`${data.expedition} ${data.service}`]) {
          temp[`${data.expedition} ${data.service}`] = {};
        }
        if (!temp[`${data.expedition} ${data.service}`].totalNotChecked) {
          temp[`${data.expedition} ${data.service}`].totalNotChecked = 1;
          temp[`${data.expedition} ${data.service}`].totalChecked = 0;

          if (checked) {
            temp[`${data.expedition} ${data.service}`].totalChecked += 1;
          }
        } else {
          if (checked) {
            temp[`${data.expedition} ${data.service}`].totalChecked += 1;
            setChecked("");
          }
        }

        if (!temp[`${data.expedition} ${data.service}`].data)
          temp[`${data.expedition} ${data.service}`].data = [];
        temp[`${data.expedition} ${data.service}`].data.push(temp2);
      }
      const sorted = Object.keys(temp)
        .sort()
        .reduce((accumulator, key) => {
          accumulator[key] = temp[key];

          return accumulator;
        }, {});
      setDataValidate(sorted);
      setRes({ status: "success", message: "berhasil edit data order" });
      setShowResponse(true);
    } catch (error) {
      const message = await handleError(error);
      setError(message);
      setRes({ status: "error", message: "gagal edit data order" });
      setShowResponse(true);
    }
  };
  //edit transaction

  //=================

  //for show print preview

  const getPrintdata = async (e) => {
    try {
      setErrorPrint("");
      setLoadingUpload(true);

      const data = await getOrdersPrint({ date: e, seller });
      if (!data.length) {
        setErrorPrint("Tidak ada data");
      } else {
        setText("Loading....");
        setAllPrintData(data);
      }
      setLoadingUpload(false);
    } catch (error) {
      setErrorPrint("Gagal mencetak Surat Jalan, coba lagi nanti.");
      setLoadingUpload(false);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef,
    documentTitle: "Surat Jalan",
    onAfterPrint: () => {
      setPrintData([]);
    },
    pageStyle: `
      @page {size: 353mm 500mm;}
      `,
  });
  //for show print preview

  //=================

  //for upload file
  const fileHandler = async (e) => {
    try {
      setText("Upload sedang berlangsung");
      setLoadingUpload(true);
      setError("");
      setErrorUploadChecked([]);
      e.preventDefault();
      const getFiles = (() => {
        try {
          return e.target.files[0];
        } catch (e) {
          return null;
        }
      })();
      if (!/xls/i.test(getFiles.name)) {
        setErrorUploadChecked([{ status: "Harap format excel bertipe xlxs" }]);
        setLoadingUpload(false);
        setOpenModalErrorChecked(true);
        setOpenModalUpload(false);
        return;
      }
      const f = await getFiles.arrayBuffer();
      const wb = read(f);
      const sheet = wb.SheetNames;
      const ws = wb.Sheets[sheet[0]];
      Object.keys(ws).forEach((s) => {
        if (/c/i.test(s)) {
          if (s !== "C1") {
            if (typeof ws[s].v === "string") {
              ws[s].w = ws[s].w.split("/");

              const element = ws[s].w.splice(0, 1)[0];

              ws[s].w.splice(1, 0, element);
              ws[s].w = ws[s].w.join("/");
            }
            // else {
            //   ws[s].w = ws[s].w.split("/");

            //   ws[s].w[2] = `20${ws[s].w[2]}`;
            //   ws[s].w = ws[s].w.join("/");
            // }
          }
        }
      });

      const data = utils.sheet_to_json(ws, {
        raw: false,
      }); //? UBAH DATA DARI EXEL KE DALAM BENTUK JSON

      const excel = utils.sheet_to_json(ws, { header: 1 });
      let statusError = false;

      if (typeUpload === "checker") {
        //! VALIDASI EXEL SEBELUM DI KIRIM KE GATEWAY
        if (excel[0][0] !== "No.awb") {
          setErrorUploadChecked([
            { status: "Format table salah harap menggunakan tamplate terbaru" },
          ]);
          statusError = true;
        } else if (data.length === 1 && !data[0]["No.awb"]) {
          statusError = true;

          setErrorUploadChecked([{ status: "Data tidak Ada" }]);
        } else {
          setLoadingDetail(true);

          //? MELAKUKAN PENGECEKAN APAKAH AWB SEBELUMNYA SUDAH DIUPLOAD ATAU BELUM
          const _data = await checkedOrder({ noawb_order: data, seller });
          if (_data.update.length) {
            let temp1 = [...dataChecked];

            Object.keys(dataValidate).forEach((e, i) => {
              if (e !== "total") {
                let find = dataValidate[e].data.filter((e) =>
                  _data.update.find(
                    (e1) =>
                      e1.awb.toLowerCase() === e.awb.toLowerCase() &&
                      e1.order_id.toLowerCase() === e.order_id.toLowerCase()
                  )
                );
                if (find.length) {
                  let temp = dataValidate[e];

                  find.forEach((e1, i) => {
                    e1.checkedDate = _data.update[i].checkedDate;
                    temp1.push(e1);
                    temp.totalChecked += 1;
                  });
                  // temp.data.sort((a, b) => a.checkedDate - b.checkedDate);

                  setDataValidate({
                    ...dataValidate,
                    [e]: temp,
                  });
                  setLoadingDetail(false);

                  setDataChecked(temp1);
                }
              }
            });
            let temp = [...dataList];

            dataList.forEach((e, i) => {
              let find = _data.update.find(
                (e1) =>
                  `${e1.awb}`.toLowerCase() === `${e.awb}`.toLowerCase() &&
                  `${e1.order_id}`.toLowerCase() ===
                    `${e.order_id}`.toLowerCase()
              );
              if (find) {
                temp[i].checkedDate = find.checkedDate;
              }
            });
            setDataList(temp);

            // let index = dataList.findIndex((e) =>
            //   _data.data.find(
            //     (e1) =>
            //       `${e1}`.toLowerCase() === `${e.awb}`.toLowerCase() ||
            //       `${e1}`.toLowerCase() === `${e.order_id}`.toLowerCase()
            //   )
            // );
            // if (index !== -1) {
            //   let temp = [...dataList];
            //   temp[index].checkedDate = new Date();

            //   setDataList(temp);
            // }
          }
          // if (_data.error.length) {
          //   setErrorUploadChecked(_data.error);
          //   setOpenModalErrorChecked(true);
          // }
        }
      } else {
        if (
          !/Nomor Order/i.test(excel[0][0]) ||
          excel[0][1].trim() !== "*Nomor Resi" ||
          excel[0][2].trim() !== "*Tanggal Order (DD/MM/YYYY)" ||
          excel[0][3].trim() !== "*Ekspedisi" ||
          excel[0][4].trim() !== "Service (optional)"
        ) {
          setErrorUploadChecked([
            { status: "Format table salah harap menggunakan tamplate terbaru" },
          ]);
          statusError = true;
        } else if (
          data.length === 1 &&
          !data[0]["*Nomor Order (optional)"] &&
          !data[0]["*Nomor Resi"] &&
          !data[0]["*Tanggal Order (DD/MM/YYYY)"] &&
          !data[0]["*Ekspedisi"] &&
          !data[0]["Service (optional)"]
        ) {
          statusError = true;

          setErrorUploadChecked([{ status: "Data tidak Ada" }]);
        } else {
          let last = false;

          let _newData = { errors: [], totalCreate: 0, totalUpdate: 0 };
          while (!last) {
            let newData = data.splice(0, 10000);

            last = data.length === 0;
            let compressed = compress(newData);

            //! UPLOAD EXEL, AKAN DIKIRIM KE BACKEND DAN AKAN DICREATE DI DATABASE.S
            const _data = await uploadOrder(compressed, seller);
            _newData.errors = _newData.errors.concat(_data.errors);
            _newData.totalCreate += _data.totalCreate;
            _newData.totalUpdate += _data.totalUpdate;
          }
          if (_newData.errors.length) {
            setErrorUploadChecked(_newData.errors);
            setOpenModalErrorChecked(true);
            statusError = true;
          }
          if (_newData.totalCreate || _newData.totalUpdate) {
            await getOrderFile();

            await getOrderValidate();
          }
        }
      }

      setLoadingUpload(false);
      setOpenModalUpload(false);
      if (statusError) {
        setOpenModalErrorChecked(true);
      } else {
        setRes({ status: "success", message: "berhasil upload data order" });
        setShowResponse(true);
      }
      // setFileName(getFiles.name);
    } catch (err) {
      const message = await handleError(err);
      console.log(message);
      if (Array.isArray(message)) {
        if (message.length) {
          setErrorUploadChecked(message);
        }
      } else if (message === "Tidak ada koneksi") {
        setErrorUploadChecked([{ status: message }]);
      } else {
        setErrorUploadChecked([message]);
      }

      setLoadingUpload(false);
      setOpenModalUpload(false);
      setOpenModalErrorChecked(true);
      setRes({ status: "error", message: "gagal upload data order" });
      setShowResponse(true);
    }
    e.target.value = null;
  };

  const downloadTemplate = async () => {
    try {
      let rows;
      let wscols;

      if (typeUpload === "checker") {
        rows = [
          {
            "No.awb": "",
          },
        ];

        wscols = [{ width: 20 }];
      } else {
        rows = [
          {
            "Nomor Order (optional)": "",
            "*Nomor Resi": "",
            "*Tanggal Order (DD/MM/YYYY)": "",
            "*Ekspedisi": "",
            "Service (optional)": "",
          },
        ];

        // wscols = [
        //   { width: rows[0]["Nomor Order"].length },
        //   { width: rows[0]["Nomor Resi"].length },
        //   { width: rows[0]["Tanggal Order"].length },
        //   { width: rows[0]["Ekspedisi"].length },
        //   { width: rows[0]["Service"].length },
        // ];

        wscols = [
          { width: 26 },
          { width: 20 },
          { width: 40 },
          { width: 20 },
          { width: 20 },
        ];
      }

      const ws = utils.json_to_sheet(rows);
      ws["!cols"] = wscols;
      const wb = utils.book_new();

      utils.book_append_sheet(wb, ws, "Data");
      if (typeUpload === "checker") writeFile(wb, "Hasil_Scan_Order.xlsx");
      else writeFile(wb, "Upload_Order.xlsx");
      setRes({ status: "success", message: "berhasil download template" });
      setShowResponse(true);
    } catch (error) {
      const message = await handleError(error);
      if (message.cancel) return true;
      setRes({ status: "error", message: "gagal download template" });
      setShowResponse(true);
    }
  };
  //for upload file

  //=================

  //for scan manual
  const scanSubmit = async (e) => {
    try {
      setErrorScan("");
      setError("");
      e.preventDefault();
      setLoadingScan(true);
      setLoadingDetail(true);
      // const data = await checkedOrder({ noawb_order: scanAwb.toLowerCase() });
      //! check ke database
      const data = await checkedOrder({
        noawb_order: inputScanAwb.current.value.toLowerCase(),
        seller,
      });
      let result;
      let findResult;
      Object.keys(dataValidate).forEach((e, i) => {
        if (e !== "total") {
          let find = dataValidate[e].data.find((e) => {
            return (
              e.awb.toLowerCase().trim() ===
                data.update.awb.toLowerCase().trim() &&
              e.order_id.toLowerCase().trim() ===
                data.update.order_id.toLowerCase().trim()
            );
          });

          if (find) {
            result = e;
            findResult = find;
            return;
          }
        }
      });

      setLoadingScan(false);

      if (result && findResult) {
        findResult.checkedDate = data.update.checkedDate;

        let temp = dataValidate[result];
        temp.totalChecked += 1;
        // temp.data.sort((a, b) => b.checkedDate - a.checkedDate);

        setDataValidate({
          ...dataValidate,
          [result]: temp,
        });
        setLoadingDetail(false);

        const findIndex = dataList.findIndex(
          (e) =>
            e.awb.toLowerCase().trim() ===
              inputScanAwb.current.value.toLowerCase().trim() ||
            e.order_id.toLowerCase().trim() ===
              inputScanAwb.current.value.toLowerCase().trim()
        );

        if (findIndex > -1) {
          let temp = [...dataList];
          temp[findIndex].checkedDate = data.update.checkedDate;
          setDataList(temp);
        }
        let temp2 = [...dataChecked];
        temp2.unshift(findResult);
        setDataChecked(temp2); // Data disimpan dalam data Checked
        //
        // setDataChecked([...dataChecked, findResult]);
      }
      scrollToUpper();
      setLoadingScan(false);

      audioCorrect.play();
      e.target.reset();
    } catch (error) {
      setLoadingScan(false);
      const message = await handleError(error);
      setErrorScan(message);
      if (message === "Tidak ada koneksi") {
        setError(message);
      }
      audioWrong.play();
      e.target.reset();
    }
  };
  //for scan manual

  //=================

  // get all transaction data
  const getOrderFile = async () => {
    try {
      setError("");

      setLoading(true);
      let temp = [...dataList];
      temp = [];
      setDataList(temp);
      // setCount(0);
      const data = await getOrders(search);
      setCount(data.count);
      setDataList(data.rows);
      setLoading(false);
    } catch (error) {
      const message = await handleError(error);
      setLoading(false);
      setError(message);
    }
  };
  //

  //=================

  // get all scanned total data
  const getOrderValidate = async () => {
    try {
      setLoadingValidate(true);
      setError("");
      setDataValidate({});

      const data = await getOrdersValidate(search);
      setDataValidate(data.data);
      setLoadingValidate(false);
    } catch (error) {
      setLoadingValidate(false);

      const message = await handleError(error);
      setLoading(false);
      setError(message);
    }
  };
  //

  //=================

  // get all scanned  data
  const getChecked = async () => {
    try {
      setError("");

      setLoadingChecked(true);
      const data = await getCheckedOrder({ type: "order", seller });
      // setDataChecked(data);
      setDataCheckedTotal(data);

      // setDataValidate(data.data);
      setLoadingChecked(false);
    } catch (error) {
      const message = await handleError(error);
      setError(message);
    }
  };
  //

  //=================

  // delete order temporary
  const deleteHandler = async (id) => {
    try {
      setError("");

      if (params.status === "delete" && accessStatus === "root") {
        await deleteOrderPermanent(id);
      } else {
        await deleteOrder(id);
        Object.keys(dataValidate).forEach((e, i) => {
          if (e !== "total") {
            let find = dataValidate[e].data.find((e) => {
              return e.id === id;
            });
            if (find) {
              let temp = { ...dataValidate };
              let key = e;
              if (temp[key].totalChecked > 0) {
                if (find.checkedDate) temp[key].totalChecked -= 1;
              }

              if (temp[key].totalNotChecked === 1) {
                delete temp[key];
              } else {
                temp[key].totalNotChecked -= 1;
                let index = temp[key].data.findIndex((e) => e.id === id);
                if (index > -1) {
                  temp[key].data.splice(index, 1);
                }
              }
              if (temp.total > 0) {
                temp.total -= 1;
              }
              setDataValidate(temp);
              return;
            }
          }
        });
      }

      setRes({ status: "success", message: "berhasil hapus order" });
      setShowResponse(true);

      await getOrderFile();
    } catch (error) {
      const message = await handleError(error);
      setError(message);
      setRes({ status: "error", message: "gagal upload data order" });
      setShowResponse(true);
    }
  };
  // delete order temporary

  // restore order temporary
  const restoreHandler = async (data) => {
    try {
      setError("");

      await restoreOrder(data.id);
      let find = Object.keys(dataValidate).find(
        (e, i) => e === `${data.expedition} ${data.service}`
      );

      if (find) {
        let temp = Object.assign({}, dataValidate);
        temp[`${data.expedition} ${data.service}`] = {
          totalChecked: data.checkedDate
            ? dataValidate[`${data.expedition} ${data.service}`].totalChecked +
              1
            : dataValidate[`${data.expedition} ${data.service}`].totalChecked,
          totalNotChecked:
            dataValidate[`${data.expedition} ${data.service}`].totalNotChecked +
            1,
          data: [
            ...dataValidate[`${data.expedition} ${data.service}`].data,
            data,
          ],
        };
        temp.total = dataValidate.total + 1;
        setDataValidate(temp);
      } else {
        // let temp = {
        //   totalChecked: data.checkedDate ? 1 : 0,
        //   totalNotChecked: 1,
        //   data: [data],
        // };
        //   (temp);

        setDataValidate({
          ...dataValidate,
          [`${data.expedition} ${data.service}`]: {
            totalChecked: data.checkedDate ? 1 : 0,
            totalNotChecked: 1,
            data: [data],
          },
          total: dataValidate.total + 1,
        });
      }

      setRes({ status: "success", message: "berhasil restore data order" });
      setShowResponse(true);

      await getOrderFile();
    } catch (error) {
      const message = await handleError(error);
      setError(message);
      setRes({ status: "error", message: "gagal restore data order" });
      setShowResponse(true);
    }
  };
  // restore order temporary

  //=================
  //waypoint detail
  const scrollDetail = async (i) => {
    setLoadingDetail(true);

    let temp1 = [...detailValidate];
    let temp2 = totalDetailValidate;
    temp2 = totalDetailValidate.slice(i + 1, i + 1 + 15);
    temp1 = temp1.concat(temp2);

    setDetailValidate(temp1);
    setLoadingDetail(false);
  };
  //waypoint detail

  //waypoint scan
  const scrollScan = async (i) => {
    setLoadingScan(true);
    let temp1 = [...dataChecked];
    let temp2 = dataCheckedTotal;
    temp2 = dataCheckedTotal.slice(i + 1, i + 1 + 15);

    temp1 = temp1.concat(temp2);
    setDataChecked(temp1);
    setLoadingScan(false);
  };
  //waypoint scan

  //event undian
  const getLotteryData = async () => {
    try {
      setLoadingSearchLottery(true);
      setDataLottery({
        no_id: "",
        awb: "",
        kodeUnique: "",
        expedition: "",
        service: "",
        order_date: "",
        lotteryStatus: "",
      });

      const data = await getDetailLottery(searchCode);

      if (!data) setDataLottery({});
      else setDataLottery(data);

      setLoadingSearchLottery(false);
    } catch (err) {
      if (err.response.data.status === 400) setDataLottery({});
      setLoadingSearchLottery(false);
      console.log(err);
    }
  };
  //event undian

  // update status paket
  // const updateStatus = async () => {
  //   try {
  //     setShowResponse(false);
  //     // setLoadingUpdateStatus(index);
  //     console.log(dataList);
  //     const data = await updateStatusPackage({ dataList });
  //     await getOrderFile();

  //     setLoadingUpdateStatus(false);
  //   } catch (err) {
  //     const message = handleError(err);
  //     console.log(message);
  //     setLoadingUpdateStatus(false);
  //     setShowResponse(true);
  //     setRes({ status: "error", message });
  //   }
  // };
  const updateStatus = async () => {
    setShowResponse(false);
    setErrorUpdateStatus([]);
    let tempError = [];

    // setErrorUpdateStatus([]);

    let temp2 = [...dataList];
    let temp3 = [];

    for (let i = 0; i < temp2.length; i++) {
      let list = temp2[i];
      setLoadingUpdateStatus(i);
      try {
        if (
          list.statusPackage !== "DELIVERED" &&
          list.statusPackage !== "RETUR"
        ) {
          const data = await updateStatusPackage({
            awb: list.awb,
            expedition: list.expedition,
            status: list.statusPackage,
            packageTimeCheck: list.packageTimeCheck,
          });
          if (data) {
            list.statusPackage = data.statusPackage;
            list.packageTimeCheck = data.packageTimeCheck;
            setDataList(temp2);
          }
          setLoadingUpdateStatus(-1);
        }
        tempError.push(false);
        // temp3.push(false);
      } catch (err) {
        const message = handleError(err);
        // list.error = message;
        // console.log(message);
        tempError.push(message);

        // temp3.push(message);
        // setLoadingUpdateStatus(-1);
        // setShowResponse(true);
        // setRes({ status: "error", message });
      }
      setErrorUpdateStatus(tempError);
    }
    // console.log(temp3, "-");
    // setErrorUpdateStatus(temp3);
    setLoadingUpdateStatus(-1);
    setRes({ status: "success", message: "berhasil update status paket" });
    setShowResponse(true);
  };
  // update status paket

  // detail status paket
  const detailStatusPackage = async ({ awb, expedition }) => {
    try {
      setShowResponse(false);
      setLoadingDetailStatus(true);
      const data = await getManifest({ awb, expedition });
      setDataManifest(data);
      setLoadingDetailStatus(false);
    } catch (err) {
      setLoadingDetailStatus(false);
      const message = handleError(err);
      setShowResponse(true);
      setRes({ status: "error", message });
      console.log(message, "---");
    }
  };

  const filterPrint = async () => {
    setDate("");
    setSelectedExpedition([]);
    setAllPrintData([]);

    let filter = allPrintData.filter((e) =>
      selectedExpedition.find((e1) => e.expedition === e1)
    );
    setPrintData(filter);
    setOpenModalPrint(false);
  };
  // detail status paket
  useEffect(() => {
    if ((params.minDate && params.maxDate) || params.search || params.status) {
      getOrderValidate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    params.minDate,
    params.maxDate,
    params.search,
    params.status,
    params.ShopId,
  ]);

  useEffect(() => {
    if (printData.length) {
      handlePrint();
      setLoadingUpload(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [printData]);

  useEffect(() => {
    getOrderFile();
    setErrorUpdateStatus([]);
    setIsEdit(-1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  // useEffect(() => {
  //   scrollToUpper();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dataChecked]);

  useEffect(() => {
    if (decode.role) {
      const searchParams = new URLSearchParams(search);
      searchParams.set("ShopId", seller);
      const newUrl = `${pathname}?${searchParams.toString()}`;
      navigate(newUrl);
    }
    // console.log(seller);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seller]);

  return (
    <Container
      sx={{
        minHeight: "100vh",
        maxHeight: {
          lg: "100vh",
          md: "100vh",
          sm: "100vh",
          xs: "auto",
        },
        width: "100%",
        display: "flex",
        paddingY: 2,
        // mt: { lg: 0, md: 0, sm: 5, xs: 5 },
      }}
    >
      <Snackbar
        open={openModalErrorChecked}
        TransitionComponent={Slide}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{ borderRadius: 0 }}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          Upload Gagal
        </Alert>
      </Snackbar>

      <Snackbar
        open={showResponse}
        onClose={() => setShowResponse(false)}
        TransitionComponent={Slide}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{ borderRadius: 0 }}
      >
        <Alert
          onClose={() => setShowResponse(false)}
          severity={res.status}
          sx={{ width: "100%" }}
        >
          {res.message}
        </Alert>
      </Snackbar>
      {/* modal loading */}
      <ModalLoading loadingUpload={loadingUpload} text={text} />
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        sx={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Zoom in={openModal}>
          <Box
            sx={{
              width: matchesMobile ? "90%" : "auto",
              height: "auto",
              backgroundColor: "white",
              borderRadius: 1,
              boxShadow: 24,
              p: 2,
              display: "flex",
              justifyContent: "center",
              // alignItems: "center",
              flexDirection: "column",
            }}
          >
            <DateRangePicker
              onChange={(item) => setState(item.selection)}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={matchesMobile ? 1 : 2}
              ranges={[state]}
              direction={matchesMobile ? "vertical" : "horizontal"}
              rangeColors={["#333333", "#ab8c36"]}
              color="#ff1100"
              // fixedHeight={matchesMobile ? true : false}
              inputRanges={matchesMobile ? [] : undefined}
              staticRanges={matchesMobile ? [] : undefined}
              // inputRanges={[]}
              // staticRanges={[]}
              className={
                matchesMobile
                  ? {
                      rdrStaticRanges: {
                        width: "0px",
                      },
                      rdrDateRangePickerWrapper: {
                        display: "flex !important",
                        justifyContent: "center !important",
                        alignItems: "center !important",
                        width: "100% !important",
                        position: "relative !important",
                      },
                    }
                  : null
              }
            />

            <Grid
              item
              width="100%"
              sx={{ display: "flex", justifyContent: "end" }}
            >
              <Button
                onClick={() => setOpenModal(false)}
                style={{ marginRight: 15 }}
                variant="outlined"
                sx={{
                  borderColor: "#ab8c36",
                  color: "#ab8c36",
                  width: "10%",
                  "&:hover": {
                    backgroundColor: "#ffffff",
                    borderColor: "#ab8c36",
                    transform: "scale3d(1.1, 1.2, 1)",
                  },
                }}
              >
                Batal
              </Button>
              <Button
                onClick={() => {
                  push({
                    ...query,
                    minDate: moment(state.startDate).format("MM-DD-YYYY"),
                    maxDate: moment(state.endDate).format("MM-DD-YYYY"),
                  });
                  // if (isAppleProduct) {
                  //   let newMinDate = new Date(
                  //     state.startDate
                  //   ).toLocaleDateString("en-US", options);

                  //   let newMaxDate = new Date(state.endDate).toLocaleDateString(
                  //     "en-US",
                  //     options
                  //   );

                  //   newMinDate = newMinDate.split("/");
                  //   newMaxDate = newMaxDate.split("/");

                  //   const tempDateMin = newMinDate[1];
                  //   const tempMonthMin = newMinDate[0];

                  //   const tempDateMax = newMaxDate[1];
                  //   const tempMonthMax = newMaxDate[0];
                  //   newMinDate[0] = tempDateMin;
                  //   newMinDate[1] = tempMonthMin;

                  //   newMaxDate[0] = tempDateMax;
                  //   newMaxDate[1] = tempMonthMax;
                  //   newMinDate = newMinDate.join("-");
                  //   newMaxDate = newMaxDate.join("-");

                  //   push({
                  //     ...query,
                  //     minDate: newMinDate,
                  //     maxDate: newMaxDate,
                  //     // minDate: moment(state.startDate).format("MM-DD-YYYY"),
                  //     // maxDate: moment(state.endDate).format("MM-DD-YYYY"),
                  //   });
                  // } else {
                  //   push({
                  //     ...query,
                  //     // minDate: moment(state.startDate).format("MM-DD-YYYY"),
                  //     minDate: state.startDate
                  //       .toLocaleDateString("en-US", options)
                  //       .replaceAll("/", "-"),
                  //     // maxDate: moment(state.endDate).format("MM-DD-YYYY"),
                  //     maxDate: state.endDate
                  //       .toLocaleDateString("en-US", options)
                  //       .replaceAll("/", "-"),
                  //   });
                  // }
                  getOrderValidate();
                  setOpenModal(false);
                }}
                variant="contained"
                sx={{
                  backgroundColor: "#333333",
                  width: "10%",
                  "&:hover": {
                    backgroundColor: "#333333",
                    transform: "scale3d(1.1, 1.2, 1)",
                  },
                }}
              >
                Cari
              </Button>
            </Grid>
          </Box>
        </Zoom>
      </Modal>

      {/* ComponentToPrint */}
      <Box sx={{ display: "none" }}>
        <ComponentToPrint
          ref={(el) => (componentRef = el)}
          data={printData}
          date={date}
        />
      </Box>

      {/* ComponentToPrint */}

      <Modal
        open={openModalPrint}
        onClose={() => {
          setOpenModalPrint(false);
          setAllPrintData([]);
          setSelectedExpedition([]);

          setErrorPrint();
        }}
        sx={{
          // backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Zoom in={openModalPrint}>
          <Box
            sx={{
              width: matchesMobile ? "90%" : "30%",
              height: "auto",
              backgroundColor: "white",
              borderRadius: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              pb: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "10%",
                width: "100%",
                marginY: 1,
              }}
            >
              <Typography variant="h4" component="h2" sx={{ ml: 2 }}>
                Print Surat Jalan
              </Typography>
              <Button
                size="small"
                sx={{
                  "&:hover": { backgroundColor: "transparent" },
                }}
                onClick={() => {
                  setOpenModalPrint(false);
                  setErrorPrint("");
                }}
              >
                <Close
                  sx={{
                    color: "#bababa",
                    "&:hover": { color: "#333333" },
                  }}
                />
              </Button>
            </Box>
            <Box
              sx={{
                height: "90%",
                width: "90%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "column",
                mt: 2,
                // paddingX: 4,
                // pb: 2,
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Pilih Tanggal"
                  inputFormat="dd/MM/yyyy"
                  value={date}
                  onChange={(e) => {
                    setDate(e);
                    getPrintdata(e);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
              {allPrintData.length ? (
                <FormControl sx={{ m: 1, width: 300 }}>
                  <InputLabel id="select-expedition-label">
                    Pilih Ekspedisi
                  </InputLabel>
                  <Select
                    labelId="select-expedition-label"
                    id="select-expedition"
                    multiple
                    value={selectedExpedition}
                    onChange={selectPrintedExpedition}
                    input={<OutlinedInput label="Pilih Ekspedisi" />}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                  >
                    {allPrintData.map((e) => (
                      <MenuItem key={e.expedition} value={e.expedition}>
                        <Checkbox
                          checked={
                            selectedExpedition.indexOf(e.expedition) > -1
                          }
                        />
                        <ListItemText primary={e.expedition} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : null}

              {errorPrint ? (
                // <Alert severity="error" sx={{ mt: 2 }}>
                //   {errorPrint}
                // </Alert>
                <Typography
                  severity="error"
                  sx={{ mt: 2, color: "red", fontWeight: "bold" }}
                >
                  {errorPrint}
                </Typography>
              ) : null}
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                  mt: 3,
                }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    mr: 2,
                    borderColor: "#ab8c36",
                    color: "#ab8c36",
                    width: "10%",
                    "&:hover": {
                      backgroundColor: "#ffffff",
                      borderColor: "#ab8c36",
                      transform: "scale3d(1.1, 1.2, 1)",
                    },
                  }}
                  onClick={() => {
                    setDate("");
                    setAllPrintData([]);
                    setSelectedExpedition([]);

                    // setNotif("");
                    // setThreePl("");
                    setOpenModalPrint(false);
                    setErrorPrint("");
                  }}
                >
                  Batal
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#333333",
                    width: "10%",
                    "&:hover": {
                      backgroundColor: "#333333",
                      transform: "scale3d(1.1, 1.2, 1)",
                    },
                  }}
                  // onClick={getPrintdata}
                  onClick={filterPrint}
                >
                  Print
                </Button>
              </Box>
            </Box>
          </Box>
        </Zoom>
      </Modal>

      {/* modal upload */}
      <Modal
        open={openModalUpload}
        onClose={() => setOpenModalUpload(false)}
        sx={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Zoom in={openModalUpload}>
          <Box
            sx={{
              width: matchesMobile ? "90%" : "50%",
              height: "50%",
              backgroundColor: "white",
              borderRadius: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "10%",
                width: "100%",
                marginY: 1,
              }}
            >
              <Typography variant="h4" sx={{ ml: 2 }}>
                {typeUpload === "checker"
                  ? "Upload Hasil Checker"
                  : "Upload Order Marketplace"}
              </Typography>
              <Button
                size="small"
                sx={{
                  "&:hover": { backgroundColor: "transparent" },
                }}
                onClick={() => {
                  setOpenModalUpload(false);
                }}
              >
                <Close
                  sx={{
                    color: "#bababa",
                    "&:hover": { color: "#333333" },
                  }}
                />
              </Button>
            </Box>
            <Box
              sx={{
                height: "90%",
                width: "90%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                // paddingX: 4,
                // pb: 2,
              }}
            >
              <Box
                component="label"
                sx={{
                  width: "100%",
                  height: "80%",
                  border: 2,
                  borderRadius: 2,
                  borderColor: "#bababa",
                  borderStyle: "dashed",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  color: "#bababa",
                  "&:hover": {
                    cursor: "pointer",
                    color: "#333333",
                    borderColor: "#333333",
                  },
                }}
              >
                <input type="file" hidden onChange={fileHandler} />
                <Typography sx={{ fontSize: 15 }}>Klik Disini</Typography>
              </Box>

              <Box
                sx={{
                  width: "100%",
                  height: "20%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Button
                  onClick={() => downloadTemplate()}
                  variant="outlined"
                  sx={{
                    borderColor: "#333333",
                    color: "#333333",
                    "&:hover": {
                      borderColor: "#333333",
                      backgroundColor: "#333333",
                      color: "#ffffff",
                    },
                  }}
                >
                  Download Template
                </Button>
              </Box>
            </Box>
          </Box>
        </Zoom>
      </Modal>

      {/* modal error */}
      <Modal
        open={openModalErrorChecked}
        sx={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Zoom in={openModalErrorChecked}>
          <Box
            sx={{
              width: "50%",
              height: "50%",
              backgroundColor: "white",
              borderRadius: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                height: "10%",
                width: "100%",
              }}
            >
              <Button
                size="small"
                sx={{
                  color: "#bababa",
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: "#333333",
                  },
                }}
                onClick={() => {
                  setOpenModalErrorChecked(false);
                }}
              >
                <Close />
              </Button>
            </Box>
            <Box
              sx={{
                height: "85%",
                width: "100%",
              }}
            >
              <TableContainer
                sx={{
                  backgroundColor: "#ffffff",
                  maxHeight: "100%",
                }}
              >
                <Table
                  sx={{
                    backgroundColor: "#ffffff",
                  }}
                  size="small"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>No.</TableCell>
                      <TableCell>Keterangan</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {errorUploadChecked.length
                      ? errorUploadChecked.map((e, i) => (
                          <TableRow key={i}>
                            <TableCell>{i + 1}</TableCell>
                            <TableCell>
                              {`${e.awb ? e.awb : ""} ${
                                e.status ? e.status : ""
                              }`}
                            </TableCell>
                          </TableRow>
                        ))
                      : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Zoom>
      </Modal>

      {/* modal detail validasi order */}
      <Modal
        open={openModalDetailValidation}
        onClose={() => setOpenModalDetailValidation(false)}
        sx={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Zoom in={openModalDetailValidation}>
          <Box
            sx={{
              width: matchesMobile ? "85%" : "70%",
              height: "50%",
              backgroundColor: "white",
              borderRadius: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "10%",
                width: "100%",
              }}
            >
              <Typography variant="h4" sx={{ ml: 2 }}>
                Detail
              </Typography>
              <Button
                size="small"
                sx={{
                  color: "#bababa",
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: "#333333",
                  },
                }}
                onClick={() => {
                  setDetailValidate([]);
                  setOpenModalDetailValidation(false);
                }}
              >
                <Close />
              </Button>
            </Box>
            <Box
              sx={{
                height: "85%",
                width: "100%",
              }}
            >
              <TableContainer
                sx={{
                  backgroundColor: "#ffffff",
                  maxHeight: "100%",
                }}
              >
                <Table
                  sx={{
                    backgroundColor: "#ffffff",
                  }}
                  size="small"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>No</TableCell>
                      <TableCell>No. Order</TableCell>
                      <TableCell>No. Resi</TableCell>
                      <TableCell>Logistik</TableCell>
                      <TableCell>Service</TableCell>
                      <TableCell align="center">Status Scan</TableCell>
                      <TableCell>Tanggal</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {detailValidate?.length
                      ? detailValidate?.map((e, i) => (
                          <TableRow
                            key={i}
                            sx={{
                              backgroundColor: e.checkedDate
                                ? "#b5eecd30"
                                : "#f8c1ba30",
                            }}
                          >
                            <TableCell>{i + 1}</TableCell>
                            <TableCell>
                              {isNaN(e.order_id)
                                ? e.order_id.toUpperCase()
                                : e.order_id}
                            </TableCell>
                            <TableCell>
                              {isNaN(e.awb) ? e.awb.toUpperCase() : e.awb}
                            </TableCell>
                            <TableCell>{e.expedition.toUpperCase()}</TableCell>
                            <TableCell>{e.service.toUpperCase()}</TableCell>
                            <TableCell align="center">
                              {e.checkedDate ? (
                                <DoneOutlined sx={{ color: "#a8a8a8" }} />
                              ) : null}
                            </TableCell>
                            <TableCell>
                              {new Date(e.order_date).toLocaleDateString(
                                "en-US",
                                options
                              )}
                            </TableCell>
                            <TableCell>
                              {i === detailValidate.length - 1 ? (
                                <Waypoint onEnter={() => scrollDetail(i)} />
                              ) : null}
                            </TableCell>
                          </TableRow>
                        ))
                      : null}
                  </TableBody>
                </Table>
                {!dataValidate?.length && openModalDetailValidation ? (
                  <Waypoint
                    onEnter={() => {
                      let temp;
                      setLoadingDetail(true);

                      if (totalDetailValidate?.length > 15) {
                        temp = totalDetailValidate.slice(0, 15);
                      } else {
                        temp = totalDetailValidate;
                      }
                      if (typeof detailValidate === "object")
                        setDetailValidate(temp);
                      else setDetailValidate(...detailValidate, temp);

                      setLoadingDetail(false);
                    }}
                  />
                ) : null}
                {loadingDetail ? (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress size={25} sx={{ color: "#ffffff" }} />
                  </Box>
                ) : null}
              </TableContainer>
            </Box>
          </Box>
        </Zoom>
      </Modal>

      {/* modal konfirmasi hapus*/}
      <Modal
        open={openModalDelete}
        onClose={() => setOpenModalDelete(false)}
        sx={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Zoom in={openModalDelete}>
          <Box
            sx={{
              width: matchesMobile ? "auto" : "auto",
              backgroundColor: "white",
              p: 3,
              borderRadius: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box>
              <Typography variant="h4">
                {`Apakah Kamu yakin menghapus Order ini ?`}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                mt: 5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                onClick={(e) => {
                  setOpenModalDelete(false);
                  setIdDelete(0);
                }}
                style={{ marginRight: 15 }}
                variant="outlined"
                sx={{
                  borderColor: "#ab8c36",
                  color: "#ab8c36",
                  width: "10%",
                  "&:hover": {
                    backgroundColor: "#ffffff",
                    borderColor: "#ab8c36",
                    transform: "scale3d(1.1, 1.2, 1)",
                  },
                }}
              >
                Batal
              </Button>
              <Button
                onClick={(e) => {
                  deleteHandler(idDelete);
                  setOpenModalDelete(false);
                }}
                variant="contained"
                sx={{
                  width: "10%",
                  backgroundColor: "#bd454f",
                  "&:hover": {
                    backgroundColor: "#bd454f",
                    transform: "scale3d(1.1, 1.2, 1)",
                  },
                }}
              >
                Hapus
              </Button>
            </Box>
          </Box>
        </Zoom>
      </Modal>

      {/* modal konfirmasi restore */}
      <Modal
        open={openModalRestore}
        onClose={() => setOpenModalRestore(false)}
        sx={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Zoom in={openModalRestore}>
          <Box
            sx={{
              width: matchesMobile ? "auto" : "auto",
              backgroundColor: "white",
              p: 3,
              borderRadius: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box>
              <Typography variant="h4">
                {`Apakah Kamu yakin Restore Order ini ?`}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                mt: 5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                onClick={(e) => {
                  setOpenModalRestore(false);
                  setSelectedData({});
                }}
                style={{ marginRight: 15 }}
                variant="outlined"
                sx={{
                  borderColor: "#ab8c36",
                  color: "#ab8c36",
                  width: "10%",
                  "&:hover": {
                    backgroundColor: "#ffffff",
                    borderColor: "#ab8c36",
                    transform: "scale3d(1.1, 1.2, 1)",
                  },
                }}
              >
                Batal
              </Button>
              <Button
                onClick={(e) => {
                  restoreHandler(selectedData);
                  setOpenModalRestore(false);
                }}
                variant="contained"
                sx={{
                  width: "10%",
                  backgroundColor: "#bd454f",
                  "&:hover": {
                    backgroundColor: "#bd454f",
                    transform: "scale3d(1.1, 1.2, 1)",
                  },
                }}
              >
                Restore
              </Button>
            </Box>
          </Box>
        </Zoom>
      </Modal>

      {/* Modal Undian */}
      <Modal
        open={openModalLottery}
        onClose={() => setOpenModalUpload(false)}
        sx={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Zoom in={openModalLottery}>
          <Box
            sx={{
              width: matchesMobile ? "80%" : "50%",
              height: "auto",
              backgroundColor: "white",
              borderRadius: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              p: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "98%",
                  height: "10%",
                }}
              >
                <Typography variant="h3">Cari Kode Undian</Typography>
                <IconButton
                  sx={{
                    height: "fit-content",
                    "&:hover": {
                      backgroundColor: "#ffffff20",
                    },
                  }}
                  onClick={() => {
                    setOpenModalLottery(false);
                    setDataLottery({
                      no_id: "",
                      awb: "",
                      kodeUnique: "",
                      expedition: "",
                      service: "",
                      order_date: "",
                      lotteryStatus: "",
                    });
                    setSearchCode("");
                  }}
                >
                  <Close />
                </IconButton>
              </Box>
              <Divider sx={{ mt: 2, mb: 2 }} />
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  mb: 4,
                }}
              >
                <TextField
                  value={searchCode}
                  label="Kode Unik"
                  variant="standard"
                  onChange={(e) => setSearchCode(e.target.value)}
                  onKeyPress={(e) => {
                    if (searchCode && e.key === "Enter") {
                      getLotteryData();
                    }
                  }}
                  sx={{ mr: 3 }}
                />
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#333333",
                    color: "#ffffff",
                    "&:hover": {
                      backgroundColor: "#b08c34",
                    },
                  }}
                  disabled={loadingSearchLottery}
                  onClick={(e) => {
                    if (searchCode) {
                      getLotteryData();
                    }
                  }}
                >
                  {loadingSearchLottery ? (
                    <CircularProgress size={25} sx={{ color: "#333333" }} />
                  ) : (
                    "Cari"
                  )}
                </Button>
                {dataLottery ? (
                  /winner/i.test(dataLottery.lotteryStatus) ? (
                    <Typography sx={{ ml: 2, color: "#2e7d32" }} variant="h4">
                      Selamat !!! Kamu memenangkan Undian. Informasi lebih
                      lanjut hubungi Tim Big Delivery
                    </Typography>
                  ) : null
                ) : null}
              </Box>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Nomor Order</TableCell>
                      <TableCell>Nomor Resi</TableCell>
                      <TableCell>Kode Unik</TableCell>
                      <TableCell>Logistik</TableCell>
                      <TableCell>Service</TableCell>
                      <TableCell>Tanggal</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(dataLottery).length ? (
                      <TableRow>
                        <TableCell>
                          {!dataLottery.order_id
                            ? null
                            : isNaN(dataLottery.order_id)
                            ? dataLottery.order_id.toUpperCase()
                            : dataLottery.order_id}
                        </TableCell>
                        <TableCell>
                          {!dataLottery.awb
                            ? null
                            : isNaN(dataLottery.awb)
                            ? dataLottery.awb.toUpperCase()
                            : dataLottery.awb}
                        </TableCell>
                        <TableCell>
                          {dataLottery.kodeUnique
                            ? dataLottery.kodeUnique.toUpperCase()
                            : ""}
                        </TableCell>
                        <TableCell>
                          {dataLottery.expedition
                            ? dataLottery.expedition.toUpperCase()
                            : ""}
                        </TableCell>
                        <TableCell>
                          {dataLottery.service
                            ? dataLottery.service.toUpperCase()
                            : ""}
                        </TableCell>
                        <TableCell>
                          {dataLottery.order_date
                            ? new Date(
                                dataLottery.order_date
                              ).toLocaleDateString("en-US", options)
                            : dataLottery.returDate
                            ? new Date(
                                dataLottery.returDate
                              ).toLocaleDateString("en-US", options)
                            : null}
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </TableBody>
                </Table>
                {!Object.keys(dataLottery).length ? (
                  <Alert severity="error">Tidak Ditemukan</Alert>
                ) : null}
              </TableContainer>
            </Box>
          </Box>
        </Zoom>
      </Modal>
      {/* Modal Undian */}

      {/* Modal Detail Status Paket */}
      <Modal
        open={openModalStatusPackage}
        sx={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Zoom in={openModalStatusPackage}>
          <Box
            sx={{
              width: matchesMobile ? "80%" : "50%",
              // height: "auto",
              backgroundColor: "white",
              borderRadius: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              p: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "98%",
                  height: "10%",
                }}
              >
                <Typography variant="h3">Detail Paket</Typography>
                <IconButton
                  sx={{
                    height: "fit-content",
                    "&:hover": {
                      backgroundColor: "#33333320",
                    },
                  }}
                  onClick={() => {
                    setOpenModalStatusPackage(false);
                    setDataManifest([]);
                    setSelectedStatusPackage("RETUR");
                  }}
                >
                  <Close />
                </IconButton>
              </Box>
              <Divider sx={{ mt: 2, mb: 2 }} />
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  mb: 4,
                  height: "80%",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                {loadingDetailStatus ? (
                  <CircularProgress size={25} sx={{ color: "#333333" }} />
                ) : (
                  <TableContainer
                    sx={{
                      maxHeight: 300,
                      "&::-webkit-scrollbar": { width: 10, height: 10 },
                      "&::-webkit-scrollbar-track": {
                        backgroundColor: "transparent",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#33333350",
                        borderRadius: 1,
                      },
                    }}
                  >
                    <Table>
                      <TableBody>
                        {dataManifest.map((e, i) => (
                          <TableRow key={i}>
                            <TableCell
                              sx={{ border: 0 }}
                            >{`${e.manifest_date} - ${e.manifest_time}`}</TableCell>
                            <TableCell sx={{ border: 0 }}>{`${
                              e.manifest_description
                            } ${
                              e.city_name ? ` - ${e.city_name}` : ""
                            }`}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
                {selectedStatusPackage === "RETUR" ? (
                  loadingDetailStatus ? null : (
                    <Button
                      sx={{
                        mt: 2,
                        color: "#333333",
                        borderColor: "#333333",
                        borderRadius: 0,
                        "&:hover": {
                          backgroundColor: "#33333330",
                          borderColor: "#333333",
                        },
                      }}
                      variant="outlined"
                      onClick={() => console.log("kepindah")}
                    >
                      Pindahkan ke Retur
                    </Button>
                  )
                ) : null}
              </Box>
            </Box>
          </Box>
        </Zoom>
      </Modal>
      {/* Modal Detail Status Paket */}

      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: matchesMobile ? "column" : "row",
        }}
      >
        <Box
          sx={{
            height: matchesMobile ? "75%" : "100%",
            width: matchesMobile ? "100%" : "75%",
            mr: matchesMobile ? 0 : 2,
          }}
        >
          <Box
            sx={{
              height: "20%",
              width: "100%",
              display: "flex",
              alignItems: "end",
            }}
          >
            <Box
              sx={{
                height: "100%",
                width: matchesMobile ? "70%" : "50%",
                display: "flex",
                // alignItems: "end",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  height: "70%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {decode.role ? (
                  <FormControl
                    sx={{
                      mr: 2,
                      backgroundColor: "#ffffff",
                      width: "100%",
                      boxShadow:
                        "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                    }}
                    variant="filled"
                  >
                    <InputLabel>Pilih Seller</InputLabel>
                    <Select
                      label="Pilih Seller"
                      sx={{ borderRadius: 0 }}
                      value={seller}
                      onChange={(e) => {
                        setSeller(e.target.value);
                      }}
                      get
                    >
                      {decode.users?.map((e, i) => (
                        <MenuItem key={i} value={e.ShopId}>
                          {e.username}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : null}
                <FormControl
                  sx={{
                    backgroundColor: "#ffffff",
                    width: "100%",
                    boxShadow:
                      "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                  }}
                  variant="filled"
                >
                  <TextField
                    size={matchesMobile ? "small" : "medium"}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                    }}
                    placeholder="Nomor Resi / Nomor Order"
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                    InputProps={{
                      startAdornment: params.search ? (
                        <InputAdornment position="start">
                          <IconButton
                            edge="start"
                            onClick={(e) => {
                              setScanManual(false);
                              let temp = { ...query };
                              delete temp.search;
                              const thisMonth = ThisMonth();
                              temp.minDate = thisMonth.startDate;
                              temp.maxDate = thisMonth.endDate;
                              temp.page = 1;
                              push(temp);
                              setSearchValue("");
                            }}
                          >
                            <CancelOutlined />
                          </IconButton>
                        </InputAdornment>
                      ) : (
                        <InputAdornment position="start">
                          <IconButton edge="start">
                            <Search />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onKeyPress={(e) => {
                      if (searchValue && e.key === "Enter") {
                        let temp = { ...query };
                        delete temp.minDate;
                        delete temp.maxDate;
                        temp.search = searchValue;
                        temp.page = 1;
                        push(temp);
                        // push({ ...query, search: searchValue });
                        setScanManual(false);
                      }
                      if (!searchValue && e.key === "Enter") {
                        let temp = { ...query };
                        delete temp.search;
                        const thisMonth = ThisMonth();
                        temp.minDate = thisMonth.startDate;
                        temp.maxDate = thisMonth.endDate;
                        push(temp);
                        setScanManual(false);
                      }
                    }}
                  />
                </FormControl>

                {/* {accessStatus !== "checker" ? (
                  <Tooltip title="Search Kode Unik">
                    <IconButton
                      sx={{
                        backgroundColor: "#333333",
                        boxShadow:
                          "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                        color: "#ffffff",
                        "&:hover": {
                          backgroundColor: "#b08c34",
                          boxShadow:
                            "2px -4px 5px 0px rgba(0, 0, 0, 0.5) !important",
                        },
                        ml: 2,
                      }}
                      onClick={(e) => setOpenModalLottery(true)}
                    >
                      <EmojiEvents />
                    </IconButton>
                  </Tooltip>
                ) : null} */}
              </Box>
              {scanManual ? (
                <Box
                  sx={{
                    height: "30%",
                    width: "100%",
                  }}
                />
              ) : (
                <Box
                  sx={{
                    height: "30%",
                    width: "100%",
                    display: "flex",
                    alignItems: "end",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      marginX: 1,
                      borderRadius: 0,
                      height: "100%",
                      width: "100%",
                      backgroundColor:
                        selectedStatus === "all" ? "#333333" : "#ab8c36",
                      boxShadow:
                        "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                      "&:hover": {
                        boxShadow:
                          "2px -4px 5px 0px rgba(0, 0, 0, 0.5) !important",
                        backgroundColor: "#333333",
                      },
                    }}
                    onClick={() =>
                      push({ ...query, status: "all", limit: 10, page: 1 })
                    }
                  >
                    {matchesMobile ? "All" : "All Order"}
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      marginX: 1,
                      borderRadius: 0,
                      height: "100%",
                      width: "100%",
                      backgroundColor:
                        selectedStatus === "instant" ? "#333333" : "#ab8c36",
                      boxShadow:
                        "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                      "&:hover": {
                        boxShadow:
                          "2px -4px 5px 0px rgba(0, 0, 0, 0.5) !important",
                        backgroundColor: "#333333",
                      },
                    }}
                    onClick={() =>
                      push({ ...query, status: "instant", limit: 10, page: 1 })
                    }
                  >
                    {matchesMobile ? "instant" : "Instant / Sameday"}
                  </Button>
                  {accessStatus === "root" ? (
                    <Button
                      variant="contained"
                      sx={{
                        marginX: 1,
                        borderRadius: 0,
                        height: "100%",
                        width: "100%",
                        backgroundColor:
                          selectedStatus === "delete" ? "#333333" : "#ab8c36",
                        boxShadow:
                          "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                        "&:hover": {
                          boxShadow:
                            "2px -4px 5px 0px rgba(0, 0, 0, 0.5) !important",
                          backgroundColor: "#333333",
                        },
                      }}
                      onClick={() =>
                        push({ ...query, status: "delete", limit: 10, page: 1 })
                      }
                    >
                      Deleted
                    </Button>
                  ) : null}
                </Box>
              )}
            </Box>
            {scanManual ? null : (
              <Box
                sx={{
                  height: "30%",
                  width: matchesMobile ? "30%" : "50%",
                  display: "flex",
                  alignItems: "end",
                  justifyContent: "end",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: 0,
                    width: matchesMobile ? "80%" : "50%",
                    height: "100%",
                    backgroundColor: "#ffffff",
                    color: "#000000",
                    fontSize: matchesMobile ? "8px" : "14px",
                    boxShadow:
                      "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                    "&:hover": {
                      backgroundColor: "#333333",
                      color: "#ffffff",
                    },
                  }}
                  onClick={() => setOpenModal(true)}
                >
                  {matchesMobile || !params.minDate
                    ? "Pilih Tanggal"
                    : `${formatDate(params.minDate)} - ${formatDate(
                        params.maxDate
                      )}`}
                </Button>
              </Box>
            )}
          </Box>
          <Box
            sx={{ width: "100%", height: "80%", backgroundColor: "#ffffff" }}
          >
            {scanManual ? (
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Zoom
                  in={scanManual}
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    backgroundColor: "#333333",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      height: "100%",
                      flexDirection: "column",
                      backgroundColor: "#333333",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "98%",
                        height: "10%",
                      }}
                    >
                      <Typography
                        sx={{ color: "#ffffff", fontWeight: "bold", mb: 0.5 }}
                      >
                        Scan Manual
                      </Typography>
                      <IconButton
                        sx={{
                          color: "#ffffff",
                          height: "fit-content",
                          "&:hover": {
                            backgroundColor: "#ffffff20",
                          },
                        }}
                        onClick={() => {
                          setError("");
                          setScanManual(false);
                        }}
                      >
                        <Close />
                      </IconButton>
                    </Box>
                    <Box
                      component="form"
                      onSubmit={scanSubmit}
                      sx={{
                        height: "50%",
                        width: "90%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Typography sx={{ color: "#ffffff", mb: 2 }} variant="h4">
                        Data yang di Scan hari ini
                      </Typography>
                      <TableContainer
                        sx={{
                          border: 1,
                          backgroundColor: "#ffffff",
                          maxHeight: "100%",
                        }}
                      >
                        <Grid ref={upper}></Grid>
                        <Table
                          sx={{
                            backgroundColor: "#ffffff",
                          }}
                          size="small"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell align="center">NO.ORDER</TableCell>
                              <TableCell align="center">NO.RESI</TableCell>
                              <TableCell align="center">Tanggal Scan</TableCell>
                              <TableCell align="center">Waktu</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {dataChecked?.length
                              ? dataChecked.map((checked, i) => (
                                  <TableRow key={i}>
                                    <TableCell align="center">
                                      {isNaN(checked.order_id)
                                        ? checked.order_id.toUpperCase()
                                        : checked.order_id}
                                    </TableCell>
                                    <TableCell align="center">
                                      {isNaN(checked.awb)
                                        ? checked.awb.toUpperCase()
                                        : checked.awb}
                                    </TableCell>
                                    <TableCell align="center">
                                      {dateFormat(checked.checkedDate)}
                                    </TableCell>
                                    <TableCell align="center">
                                      {timeFormat(checked.checkedDate)}
                                    </TableCell>
                                    <TableCell>
                                      {i === dataChecked.length - 1 ? (
                                        <Waypoint
                                          onEnter={() => scrollScan(i)}
                                        />
                                      ) : null}
                                    </TableCell>
                                  </TableRow>
                                ))
                              : null}
                          </TableBody>
                        </Table>
                        {!dataChecked.length && scanManual ? (
                          <Waypoint
                            onEnter={() => {
                              let temp;

                              if (dataCheckedTotal.length > 15) {
                                temp = dataCheckedTotal.slice(0, 15);
                              } else {
                                temp = dataCheckedTotal;
                              }
                              if (typeof dataCheckedTotal === "object")
                                setDataChecked(temp);
                              else setDataChecked(...dataChecked, temp);
                            }}
                          />
                        ) : null}
                        {loadingChecked ? (
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CircularProgress
                              size={25}
                              sx={{ color: "#333333" }}
                            />
                          </Box>
                        ) : dataChecked.length === 0 ? (
                          <Alert
                            variant="standard"
                            severity="warning"
                            sx={{ borderRadius: 0 }}
                          >
                            Tidak ada data
                          </Alert>
                        ) : null}
                      </TableContainer>
                      {errorScan ? (
                        <Alert
                          variant="standard"
                          severity="error"
                          sx={{ position: "absolute" }}
                        >
                          {errorScan}
                        </Alert>
                      ) : null}
                    </Box>
                    <Box
                      component="form"
                      onSubmit={(e) => {
                        if (!loadingScan) scanSubmit(e);
                      }}
                      sx={{
                        height: "40%",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        autoComplete="off"
                        sx={{
                          width: "50%",
                          mb: 2,
                        }}
                        InputProps={{
                          sx: {
                            borderRadius: 0,
                            backgroundColor: "#ffffff",
                            "& input": {
                              textAlign: "center",
                              fontWeight: "bold",
                              textTransform: "uppercase",
                            },
                          },
                        }}
                        // onChange={(e) => setScanAwb(e.target.value)}
                        inputRef={inputScanAwb}
                        // onKeyPress={(ev) => {
                        //   console.log(`Pressed keyCode ${ev.key}`);
                        //   if (ev.key === "Enter") {
                        //     scanSubmit(ev);
                        //   }
                        // }}
                        autoFocus
                      />
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{
                          backgroundColor: "#ffffff",
                          color: "#333333",
                          boxShadow: loadingScan
                            ? null
                            : "0px 5px 10px 0px rgba(0, 0, 0, 0.5) !important",
                          "&:hover": {
                            backgroundColor: "#333333",
                            color: "#ffffff",
                          },
                        }}
                        disabled={loadingScan}
                      >
                        {loadingScan ? (
                          <CircularProgress
                            size={25}
                            sx={{ color: "#ffffff" }}
                          />
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </Box>
                  </Box>
                </Zoom>
              </Box>
            ) : (
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  boxShadow: "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "90%",
                    display: "flex",
                  }}
                >
                  <TableContainer
                    sx={{
                      maxHeight: "100%",
                      "&::-webkit-scrollbar": { width: 10, height: 10 },
                      "&::-webkit-scrollbar-track": {
                        backgroundColor: "transparent",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#33333350",
                        borderRadius: 1,
                      },
                    }}
                  >
                    <Table size="large" stickyHeader>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell sx={{ minWidth: 100 }}>
                            No. Order
                          </StyledTableCell>
                          <StyledTableCell sx={{ minWidth: 100 }}>
                            No. Resi
                          </StyledTableCell>
                          <StyledTableCell sx={{ minWidth: 100 }}>
                            Logistik
                          </StyledTableCell>
                          <StyledTableCell sx={{ minWidth: 100 }}>
                            Service
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Status Scan
                          </StyledTableCell>
                          <StyledTableCell sx={{ minWidth: 100 }}>
                            Tanggal
                          </StyledTableCell>
                          <StyledTableCell sx={{ minWidth: 150 }}>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              Status Paket
                              <Tooltip
                                title={
                                  <div>
                                    Paket yang bisa di cek adalah JNE, J&T,
                                    SiCepat, Ninja Express, Lion Parcel, ID
                                    Express, Anter Aja, Wahana
                                    <br />
                                    <br />
                                    Informasi lebih lanjut hubungi Big Delivery
                                  </div>
                                }
                                componentsProps={{
                                  tooltip: {
                                    sx: {
                                      backgroundColor: "#333333",
                                      color: "#ffffff",
                                    },
                                  },
                                }}
                              >
                                <InfoOutlined sx={{ fontSize: 17 }} />
                              </Tooltip>
                              {loadingUpdateStatus === -1 ? (
                                errorUpdateStatus.length ? (
                                  <IconButton>
                                    <Close
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setErrorUpdateStatus([]);
                                      }}
                                    />
                                  </IconButton>
                                ) : (
                                  <Tooltip
                                    title="update status pada data tabel di bawah"
                                    onClick={() => {
                                      updateStatus();
                                      // updateStatus({
                                      //   awb: e.awb,
                                      //   expedition: e.expedition,
                                      //   index: i,
                                      // });
                                    }}
                                  >
                                    <IconButton sx={{ color: "#ab8c36" }}>
                                      <Sync />
                                    </IconButton>
                                  </Tooltip>
                                )
                              ) : (
                                <CircularProgress
                                  size={25}
                                  sx={{ color: "#333333", ml: 2 }}
                                />
                              )}
                            </Box>
                          </StyledTableCell>
                          {/* <StyledTableCell
                            align="center"
                            sx={{ minWidth: 100 }}
                          >
                            Kode Unik
                            <Tooltip
                              title={
                                <div>
                                  Kode Unik untuk pengundian, Semoga Anda salah
                                  satunya
                                  <br />
                                  <br />
                                  Informasi lebih lanjut hubungi Big Delivery
                                </div>
                              }
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    backgroundColor: "#333333",
                                    color: "#ffffff",
                                  },
                                },
                              }}
                            >
                              <InfoOutlined sx={{ fontSize: 17 }} />
                            </Tooltip>
                          </StyledTableCell> */}
                          {accessStatus !== "checker" ? (
                            <StyledTableCell
                              align="center"
                              sx={{ minWidth: 100 }}
                            >
                              Action
                            </StyledTableCell>
                          ) : null}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dataList.length
                          ? dataList.map((e, i) =>
                              i === isEdit ? (
                                <TableRow
                                  key={i}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                    backgroundColor: "#33333330",
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    <TextField
                                      value={
                                        isNaN(editData.order_id)
                                          ? editData.order_id.toUpperCase()
                                          : editData.order_id
                                      }
                                      label="no. order"
                                      variant="standard"
                                      error={
                                        /order/i.test(error) ? true : false
                                      }
                                      helperText={
                                        /order/i.test(error)
                                          ? "sudah terdaftar"
                                          : ""
                                      }
                                      onChange={(e) =>
                                        setEditData({
                                          ...editData,
                                          order_id:
                                            e.target.value.toLowerCase(),
                                        })
                                      }
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <TextField
                                      value={
                                        isNaN(editData.awb)
                                          ? editData.awb.toUpperCase()
                                          : editData.awb
                                      }
                                      label="no.awb"
                                      variant="standard"
                                      error={/awb/i.test(error) ? true : false}
                                      helperText={
                                        /awb/i.test(error)
                                          ? "sudah terdaftar"
                                          : ""
                                      }
                                      onChange={(e) =>
                                        setEditData({
                                          ...editData,
                                          awb: e.target.value.toLowerCase(),
                                        })
                                      }
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <TextField
                                      value={editData.expedition.toUpperCase()}
                                      label="logistik"
                                      variant="standard"
                                      onChange={(e) => {
                                        setEditData({
                                          ...editData,
                                          expedition:
                                            e.target.value.toLowerCase(),
                                        });
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <TextField
                                      value={
                                        editData.service
                                          ? editData.service.toUpperCase()
                                          : editData.service
                                      }
                                      label="service"
                                      variant="standard"
                                      onChange={(e) => {
                                        setEditData({
                                          ...editData,
                                          service: e.target.value.toLowerCase(),
                                        });
                                      }}
                                    />
                                  </TableCell>

                                  <TableCell align="center">
                                    {e.checkedDate ? (
                                      <DoneOutlined sx={{ color: "#a8a8a8" }} />
                                    ) : null}
                                  </TableCell>

                                  <TableCell>
                                    <TextField
                                      value={new Date(
                                        e.order_date
                                      ).toLocaleDateString("en-US", options)}
                                      label="Tanggal"
                                      variant="standard"
                                      disabled
                                    />
                                  </TableCell>

                                  <TableCell align="center">
                                    <TextField
                                      value={
                                        e.statusPackage ? e.statusPackage : "-"
                                      }
                                      variant="standard"
                                      disabled
                                    />
                                  </TableCell>

                                  {/* <TableCell align="center">
                                    <TextField
                                      value={e.kodeUnique ? e.kodeUnique : "-"}
                                      label="Tanggal"
                                      variant="standard"
                                      disabled
                                    />
                                  </TableCell> */}

                                  <TableCell align="center">
                                    <IconButton
                                      sx={{ color: "#000000", borderRight: 1 }}
                                      onClick={(e) => {
                                        setIsEdit(-1);
                                      }}
                                    >
                                      <CancelOutlined />
                                    </IconButton>
                                    <IconButton
                                      sx={{ color: "#000000", borderRight: 1 }}
                                      onClick={(e) => editSubmit(e)}
                                    >
                                      <DoneOutlined />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ) : (
                                <TableRow key={i}>
                                  <StyledTableCell>
                                    {isNaN(e.order_id)
                                      ? e.order_id.toUpperCase()
                                      : e.order_id}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {isNaN(e.awb) ? e.awb.toUpperCase() : e.awb}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {e.expedition.toUpperCase()}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {e.service.toUpperCase()}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {e.checkedDate ? (
                                      <Tooltip
                                        title={`discan pada tanggal ${new Date(
                                          e.checkedDate
                                        ).toLocaleDateString(
                                          "en-US",
                                          options
                                        )}, di jam ${new Date(
                                          e.checkedDate
                                        ).toLocaleTimeString(
                                          "en-US",
                                          options
                                        )}`}
                                      >
                                        <DoneOutlined
                                          sx={{ color: "#a8a8a8" }}
                                        />
                                      </Tooltip>
                                    ) : null}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {new Date(e.order_date).toLocaleDateString(
                                      "en-US",
                                      options
                                    )}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {loadingUpdateStatus === i ? (
                                        <CircularProgress
                                          size={25}
                                          sx={{ color: "#333333", ml: 2 }}
                                        />
                                      ) : e.statusPackage ? (
                                        <Typography
                                          underline="hover"
                                          sx={{
                                            color:
                                              e.statusPackage === "DELIVERED"
                                                ? "#2e7d32"
                                                : e.statusPackage === "RETUR"
                                                ? "#9c27b0"
                                                : "#1976d2",
                                          }}
                                        >
                                          {e.statusPackage}
                                        </Typography>
                                      ) : (
                                        "-"
                                      )}
                                      {errorUpdateStatus.length ? (
                                        errorUpdateStatus[i] ? (
                                          <Tooltip
                                            title={errorUpdateStatus[i]}
                                            sx={{
                                              "&:hover": {
                                                backgroundColor: "transparent",
                                              },
                                              color: "#ff194390",
                                            }}
                                          >
                                            <InfoOutlined />
                                          </Tooltip>
                                        ) : null
                                      ) : null}
                                    </Box>
                                  </StyledTableCell>
                                  {/* <StyledTableCell align="center">
                                    {e.kodeUnique ? e.kodeUnique : "-"}
                                  </StyledTableCell> */}
                                  {accessStatus !== "checker" ? (
                                    <StyledTableCell align="center">
                                      {params.status === "delete" ? (
                                        <IconButton
                                          sx={{ borderRight: 1 }}
                                          onClick={() => {
                                            setOpenModalRestore(true);
                                            setSelectedData(e);
                                          }}
                                        >
                                          <RestoreFromTrashOutlined />
                                        </IconButton>
                                      ) : (
                                        <IconButton
                                          sx={{ borderRight: 1 }}
                                          onClick={() => {
                                            setPreviousExpedition(e.expedition);
                                            setPreviousService(e.service);
                                            setChecked(e.checkedDate);
                                            setEditDate(e.order_date);
                                            setEditData({
                                              ...editData,
                                              order_id: e.order_id,
                                              awb: e.awb,
                                              expedition: e.expedition,
                                              service: e.service,
                                            });
                                            setIdEdit(e.id);
                                            setIsEdit(i);
                                          }}
                                        >
                                          <EditOutlined />
                                        </IconButton>
                                      )}
                                      <IconButton
                                        sx={{ borderRight: 1 }}
                                        onClick={() => {
                                          setOpenModalDelete(true);
                                          setIdDelete(e.id);
                                        }}
                                      >
                                        <DeleteOutlined />
                                      </IconButton>
                                    </StyledTableCell>
                                  ) : null}
                                </TableRow>
                              )
                            )
                          : null}
                      </TableBody>
                    </Table>
                    {loading ? (
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <CircularProgress size={25} sx={{ color: "#333333" }} />
                      </Box>
                    ) : error ? (
                      <Alert
                        variant="standard"
                        severity="error"
                        sx={{ borderRadius: 0 }}
                      >
                        {error}
                      </Alert>
                    ) : dataList.length === 0 ? (
                      <Alert
                        variant="standard"
                        severity="warning"
                        sx={{ borderRadius: 0 }}
                      >
                        Tidak ada data
                      </Alert>
                    ) : null}
                  </TableContainer>
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "end",
                    ml: matchesMobile ? 0 : 2,
                    height: "10%",
                  }}
                >
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 25]}
                    component="div"
                    // count={data.count}
                    count={count}
                    rowsPerPage={+params.limit}
                    page={count <= 0 ? 0 : +params.page - 1}
                    onPageChange={(_, newPage) =>
                      push({ ...query, page: newPage + 1 })
                    }
                    onRowsPerPageChange={({ target: { value = "10" } }) =>
                      push({ ...query, limit: value, page: 1 })
                    }
                    ActionsComponent={TablePaginationActions}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            height: matchesMobile ? "25%" : "100%",
            width: matchesMobile ? "100%" : "25%",
            display: "flex",
            alignItems: "end",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              height: matchesMobile ? "20%" : "20%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: matchesMobile ? 1 : 0,
              gap: matchesMobile ? 2 : 3,
            }}
          >
            {/* <Tooltip title="Upload data sesuai template">
              <Button
                variant="contained"
                sx={{
                  borderRadius: 0,
                  height: matchesMobile ? "100%" : "30%",
                  width: "100%",
                  mb: matchesMobile ? 1 : 2,
                  backgroundColor: "#ab8c36",
                  boxShadow: matchesMobile
                    ? null
                    : "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                  "&:hover": {
                    backgroundColor: "#333333",
                    boxShadow: "2px -4px 5px 0px rgba(0, 0, 0, 0.5) !important",
                  },
                }}
                onClick={() => setOpenModalCsv(true)}
              >
                CSV
              </Button>
            </Tooltip> */}
            <UploadWithFilters />
            {accessStatus !== "checker" ? (
              <Tooltip title="Upload data order sesuai dengan template">
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: 0,
                    height: matchesMobile ? "100%" : "30%",
                    width: "100%",
                    mb: matchesMobile ? 1 : 2,
                    backgroundColor: "#ab8c36",
                    boxShadow: matchesMobile
                      ? null
                      : "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                    "&:hover": {
                      backgroundColor: "#333333",
                      boxShadow:
                        "2px -4px 5px 0px rgba(0, 0, 0, 0.5) !important",
                    },
                  }}
                  onClick={() => {
                    setTypeUpload("orders");
                    setOpenModalUpload(true);
                  }}
                >
                  Upload
                </Button>
              </Tooltip>
            ) : null}
          </Box>

          <Box
            sx={{
              width: "100%",
              height: matchesMobile ? "80%" : "80%",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "100%",
                backgroundColor: "#333333",
                boxShadow: "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
              }}
            >
              {scanManual ? (
                <Box
                  sx={{
                    width: "100%",
                    height: "10%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#333333",
                      color: "#ffffff",
                      "&:hover": {
                        backgroundColor: "#ffffff20",
                      },
                    }}
                    onClick={() => setOpenModal(true)}
                  >
                    {`${formatDate(params.minDate)} - ${formatDate(
                      params.maxDate
                    )}`}
                  </Button>
                </Box>
              ) : null}
              <Box
                sx={{
                  width: "100%",
                  height: matchesMobile ? "50%" : scanManual ? "80%" : "90%",
                  display: "flex",
                }}
              >
                <TableContainer
                  sx={{
                    maxHeight: "100%",
                    "&::-webkit-scrollbar": { width: 10, height: 10 },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "transparent",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#ffffff50",
                      borderRadius: 1,
                    },
                  }}
                >
                  <Table size="large">
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          <Typography variant="h3" sx={{ color: "#ffffff" }}>
                            Total Order
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          <Typography
                            variant="h3"
                            sx={{ color: "#ffffff" }}
                            align={matchesMobile ? "right" : "left"}
                          >
                            {dataValidate.total ? dataValidate.total : 0}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      {matchesMobile ? null : (
                        <>
                          <TableRow>
                            <TableCell
                              sx={{ fontWeight: "bold", borderBottom: 0 }}
                            >
                              <Typography
                                variant="h4"
                                sx={{ color: "#ffffff" }}
                              >
                                List Logistik
                              </Typography>
                            </TableCell>
                          </TableRow>
                          {Object.keys(dataValidate).length
                            ? Object.keys(dataValidate).map((e, i) =>
                                e !== "total" ? (
                                  <TableRow
                                    sx={{
                                      "&:hover": {
                                        backgroundColor: "#ffffff20",
                                        cursor: "pointer",
                                      },
                                    }}
                                    key={i}
                                    onClick={async () => {
                                      setOpenModalDetailValidation(true);

                                      setTotalDetailValidate(
                                        dataValidate[e].data
                                      );
                                    }}
                                  >
                                    <TableCell
                                      sx={{
                                        fontWeight: "bold",
                                        color:
                                          dataValidate[e].totalChecked ===
                                          dataValidate[e].totalNotChecked
                                            ? "#b5eecd"
                                            : "#f8c1ba",
                                      }}
                                    >
                                      {e.toUpperCase()}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        fontWeight: "bold",
                                        color:
                                          dataValidate[e].totalChecked ===
                                          dataValidate[e].totalNotChecked
                                            ? "#b5eecd"
                                            : "#f8c1ba",
                                      }}
                                    >
                                      {`${dataValidate[e].totalChecked} / ${dataValidate[e].totalNotChecked}`}
                                    </TableCell>
                                  </TableRow>
                                ) : null
                              )
                            : null}
                        </>
                      )}
                    </TableBody>
                  </Table>
                  {loadingValidate ? (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress size={25} sx={{ color: "#ffffff" }} />
                    </Box>
                  ) : error ? (
                    <Alert
                      variant="outlined"
                      severity="error"
                      sx={{ color: "#ffffff", border: 0 }}
                    >
                      {error}
                    </Alert>
                  ) : Object.keys(dataValidate).length < 2 ? (
                    <Alert
                      variant="standard"
                      severity="warning"
                      sx={{ borderRadius: 0 }}
                    >
                      Tidak ada data
                    </Alert>
                  ) : null}
                </TableContainer>
              </Box>

              <Box
                sx={{
                  width: "100%",
                  height: matchesMobile ? "50%" : "10%",
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                {/* <ReactToPrint
                    trigger={() => {
                      // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                      // to the root node of the returned component as it will be overwritten.
                      return (
                        <Button
                          variant="contained"
                          onClick={setOpenModalPrint(true)}
                          sx={{
                            width: "40%",
                            backgroundColor: "#ab8c36",
                            borderRadius: 0,
                            boxShadow:
                              "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                            "&:hover": {
                              backgroundColor: "#ffffff",
                              boxShadow:
                                "2px -4px 5px 0px rgba(0, 0, 0, 0.5) !important",
                              color: "#333333",
                            },
                          }}
                        >
                          Print
                        </Button>
                      );
                    }}
                    onBeforePrint={async () => {
                      await PrintDoc();
                    }}
                    onAfterPrint={() => setLoadingUpload(false)}
                    content={() => componentRef}
                    pageStyle="@page {size: 353mm 500mm;}"
                  /> */}
                <Button
                  variant="contained"
                  onClick={() => {
                    setAllPrintData([]);
                    setOpenModalPrint(true);
                  }}
                  sx={{
                    width: "40%",
                    backgroundColor: "#ab8c36",
                    borderRadius: 0,
                    boxShadow:
                      "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                    "&:hover": {
                      backgroundColor: "#ffffff",
                      boxShadow:
                        "2px -4px 5px 0px rgba(0, 0, 0, 0.5) !important",
                      color: "#333333",
                    },
                  }}
                >
                  Print
                </Button>

                {/* <Button
                    onClick={handlePrint}
                    variant="contained"
                    sx={{
                      width: "40%",
                      backgroundColor: "#ab8c36",
                      borderRadius: 0,
                      boxShadow:
                        "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                      "&:hover": {
                        backgroundColor: "#ffffff",
                        boxShadow:
                          "2px -4px 5px 0px rgba(0, 0, 0, 0.5) !important",
                        color: "#333333",
                      },
                    }}
                  >
                    Print
                  </Button> */}
                <Button
                  variant="contained"
                  id="basic-button"
                  aria-controls={openMenuScan ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openMenuScan ? "true" : undefined}
                  sx={{
                    backgroundColor: "#6c6d70",
                    width: "40%",
                    borderRadius: 0,
                    boxShadow:
                      "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                    "&:hover": {
                      backgroundColor: "#ffffff",
                      boxShadow:
                        "2px -4px 5px 0px rgba(0, 0, 0, 0.5) !important",
                      color: "#333333",
                    },
                  }}
                  onClick={(e) => {
                    setOpenMenuScan(true);
                    setAnchorEl(e.currentTarget);
                  }}
                >
                  Scan
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={openMenuScan}
                  onClose={() => setOpenMenuScan(false)}
                  TransitionComponent={Fade}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    onClick={async () => {
                      if (!dataChecked.length) {
                        await getChecked();
                      } else {
                        setLoadingChecked(false);
                      }
                      setScanManual(true);
                      setOpenMenuScan(false);
                      setAnchorEl(null);
                    }}
                  >
                    Manual
                  </MenuItem>

                  <MenuItem
                    component="label"
                    onClick={() => {
                      setOpenMenuScan(false);
                      setAnchorEl(null);
                      setTypeUpload("checker");
                      setOpenModalUpload(true);
                    }}
                  >
                    Upload
                    {/* <input type="file" hidden onChange={fileHandlerScan} /> */}
                  </MenuItem>
                </Menu>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

export default OrderPage;
